
export const gradientStyle = {
    
    background: 'linear-gradient(to right, rgb(243 243 243), rgb(228 224 224 / 43%));',
    // Add more styles as needed
    minHeight: '100vh',
};

    

  